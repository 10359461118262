import { FC } from 'react';
import { TextAlignProperty } from 'csstype';

import DynamicComponent from './DynamicComponent';
import styles from './styles';
import { breakpoints, colors, fontWeights } from '../../theme';
import { NameType as IconNameType } from '../Icon';

const { h1, h2, h3, h4, subtitle, bodyText, detailText } = styles;

export const tags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'small', 'span', 'div', 'a'] as const;
export type TagProps = (typeof tags)[number];

export const fontStyles = ['normal', 'italic'] as const;
type FontStyles = (typeof fontStyles)[number];

export type Props = {
  tag?: TagProps;
  color?: keyof typeof colors;
  fontSize?: { [key in keyof typeof breakpoints | 'xs']?: number };
  fontWeight?: keyof typeof fontWeights;
  letterSpacing?: string;
  lineHeight?: number;
  fontStyle?: FontStyles;
  marginBottom?: number;
  marginTop?: number;
  icon?: IconNameType;
  textAlign?: TextAlignProperty;
};

export const H1: FC<Props> = props => (
  <DynamicComponent {...h1} {...props}>
    {props.children}
  </DynamicComponent>
);

export const H2: FC<Props> = props => (
  <DynamicComponent {...h2} {...props}>
    {props.children}
  </DynamicComponent>
);

export const H3: FC<Props> = props => (
  <DynamicComponent {...h3} {...props}>
    {props.children}
  </DynamicComponent>
);

export const H4: FC<Props> = props => (
  <DynamicComponent {...h4} {...props}>
    {props.children}
  </DynamicComponent>
);

export const Subtitle: FC<Props> = props => (
  <DynamicComponent {...subtitle} {...props}>
    {props.children}
  </DynamicComponent>
);

export const BodyText: FC<Props> = props => (
  <DynamicComponent {...bodyText} {...props}>
    {props.children}
  </DynamicComponent>
);

export const DetailText: FC<Props> = props => (
  <DynamicComponent {...detailText} {...props}>
    {props.children}
  </DynamicComponent>
);

export const Text: FC<Props> = props => (
  <DynamicComponent tag="span" {...props}>
    {props.children}
  </DynamicComponent>
);
