import { FC } from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/Icon';

import { mq } from 'utils/mixins';

import { Props } from './Typography';
import { breakpoints } from '../../theme';

const commonStyles = css<Props>`
  ${({
    theme,
    color,
    fontSize,
    fontStyle,
    fontWeight,
    letterSpacing,
    lineHeight,
    marginBottom,
    marginTop,
    textAlign,
    icon,
  }) => `
      margin-bottom: ${(typeof marginBottom !== 'undefined' && `${marginBottom}px`) || ''};
      margin-top: ${(typeof marginTop !== 'undefined' && `${marginTop}px`) || ''};
      color: ${(color && theme.colors[color]) || ''};
      font-size: ${(fontSize && `${fontSize.xs}px`) || ''};
      font-weight: ${(fontWeight && theme.fontWeights[fontWeight]) || ''};
      letter-spacing: ${letterSpacing || ''};
      line-height: ${lineHeight || ''};
      font-style: ${fontStyle || ''};
      transition: 0.15s font-size;
      text-align: ${textAlign || ''};
      ${
        icon &&
        `display: flex;
      align-items: flex-start;`
      }
    `}
`;

const createCSS = ({ fontSize }: Props) => {
  const styles = [commonStyles];

  if (fontSize) {
    let key: keyof typeof fontSize;
    for (key in fontSize) {
      if (key === 'xs') {
        styles.push(css`
          font-size: ${fontSize[key]}px;
        `);
      } else {
        styles.push(mq(breakpoints[key])`font-size: ${fontSize[key]}px`);
      }
    }
  }
  return css`
    ${styles}
  `;
};

const StyledDynamicComponent = styled.div`
  ${createCSS}
`;

const StyledIconWrapper = styled.span<{ height?: number }>`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  ${({ height }) => height && `height: ${height}em`};
  margin-right: 8px;
`;

const StyledTextWrapper = styled.span`
  text-align: left;
  vertical-align: middle;
`;

const DynamicComponent: FC<Props> = ({ tag = 'div', icon, children, ...props }) => (
  <StyledDynamicComponent as={tag} icon={icon} {...props}>
    {icon ? (
      <>
        <StyledIconWrapper height={props.lineHeight}>
          <Icon name={icon} width="1em" />
        </StyledIconWrapper>
        <StyledTextWrapper>{children}</StyledTextWrapper>
      </>
    ) : (
      children
    )}
  </StyledDynamicComponent>
);

export default DynamicComponent;
